import React from 'react';

import ItemsList from './ItemsList';
import Loading from './bits/Loading';
import API from '../apis/cs-api';
import { USERVARS, blackListCleanUp } from '../Common.js';


class Edut extends React.Component{

    state = {
        items: null,
        loading: true
    };


    componentDidMount(){
        this.loadCampaigns([]);
    }


    loadCampaigns = async sArr => {

        let items_amount = (window.BLOCKVARS_ITEMS_PER_PAGE || USERVARS.ITEMS_PER_PAGE);

        let fetchUrl ='campaignsf?page=' + Math.floor(Math.random() * ((200/items_amount) - 1) + 1) + '&limit='+items_amount;

        const response = await API.get(fetchUrl);

        this.setState({
            items: blackListCleanUp(response.data.kampanjat),
            searchTerms: this.props.searchTerms,
            loading: false
        });

       fetchUrl = null;
    };


    //RENDER APP
    render(){

            if(this.state.loading){
                return(
                    <Loading />
                )
            }

        return(
                <ItemsList items={this.state.items} />
        )
    }
}


export default Edut;