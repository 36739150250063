import React from 'react';
import Item from './Item';
import Loading from './bits/Loading';

class ItemsList extends React.Component {

    render(){

            if(!this.props.items) {
                return(
                    <Loading />
                )
            }

        let renderedList = this.props.items.map((item, index) => {
            let k = 'Item-'+item.id;
            return <Item key={k} onItemSelect={this.props.onItemSelect} item={item}/>
        });

        return(
            <div className="row cs-content-main cs-offer-list">{renderedList}</div>
        )
    }
}


export default ItemsList;