export const USERVARS = {
    //ELEMENT: '#csEdut',
    API_USER: 'csuser',
    API_PASSWORD: 'uSUzZT*&kCQ4',
    ITEMS_PER_PAGE: 12,
    URL_DIVIDER: '+',
    LANG: 'fi'
}

export const TEXT = {
    fi: {
        offers: 'Edut',
        more : 'Katso lisää',
        back: 'Takaisin',
        previous: 'Edellinen',
        next: 'Seuraava',
        page: 'Sivu',
        tostart: 'Alkuun',
        toend: 'Loppuun',
        search_results: 'Hakutulokset',
        themes: 'Teemat',
        disabled: 'Ei käytössä',

        //DB:
        ONCE: 'Kertaetu',
        ALWAYS: 'Jatkuva etu',
        TABLERESERVATION: 'Pöytävaraus',
        WEB: 'Verkkokauppa',
        btn_text_WEB: 'Siirry sivustolle'
    }
}

export const blackList = [3076]//list of campaign ids that will be blocked from website

export const blackListCleanUp =(arr)=> {
    let cleaned = []
    arr.forEach((item) =>{
            blackList.forEach((j) => {
                if(item.id !== j) cleaned.push(item)
            })
    })
    return cleaned
}

export const formatDatePretty = dateIn => {
    let dateOut = dateIn.split('-')
    return dateOut[2] + '.' + dateOut[1] + '.' + dateOut[0]
}

export const mapsSelector = (q, loc) => {
    let protocol = 'https://',
    is_uiwebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)
    //is_safari_or_uiwebview = /(iPhone|iPod|iPad).*AppleWebKit/i.test(navigator.userAgent)
   if(!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) && !is_uiwebview) {
        protocol = 'maps://'
    }
    return protocol+'www.google.com/maps?q='+loc.toString()+'+'+q+'&daddr='+loc.toString()+'&ll='
}

export const hashHandler = () => {
    var nHashArr = [[],[], 1]
    var cHashArr = nHashArr
    const thisHash = window.location.hash//.replace('/', '');
    if(thisHash !== '') {
        cHashArr = thisHash.replace('#', '').split('/')
        if (typeof cHashArr[0].split(USERVARS.URL_DIVIDER) === 'object') {
            nHashArr[0] = cHashArr[0].split(USERVARS.URL_DIVIDER).join(' ');//.replace(/ /g, USERVARS.URL_DIVIDER);//.toLowerCase()
        } else nHashArr[0] = ''

        if (typeof cHashArr[1].split(USERVARS.URL_DIVIDER) === 'object') {
            nHashArr[1] = cHashArr[1].split(USERVARS.URL_DIVIDER)
        } else nHashArr[1] = []

        //is a number / Page
        if (typeof cHashArr[2] === 'string') {
            nHashArr[2] = parseInt(cHashArr[2])//.split(USERVARS.URL_DIVIDER);
        } else nHashArr[2] = 1

        //search
        if (cHashArr[3] && cHashArr[3].indexOf('q=') >  -1) {
            nHashArr[3] = cHashArr[3].split('q=')[1].split(USERVARS.URL_DIVIDER).join(' ');//.replace(/ /g, USERVARS.URL_DIVIDER);//.toLowerCase()
        } else nHashArr[3] = ''
        if(nHashArr[0] === '*') nHashArr[0] = []
        if(nHashArr[1] === '*') nHashArr[1] = []
    }
    var retHashArr = nHashArr
    return retHashArr
}

export const crapRemoval = (str) => {
    str = makeSortString(str)
    return str.replace(/[^\w\s]/gi, '-').replace(/\s/g,'').toLowerCase()
}

export const urlCreator = (str) => {
    str = makeSortString(str)
    return str.replace(/[^\w\s]/gi, '-').replace(/\s/g, '-').toLowerCase()
}

export const makeSortString = (s) => {
    if(!makeSortString.translate_re) makeSortString.translate_re = /[öäüÖÄÜ]/g
    var translate = {
        "ä": "a", "ö": "o", "ü": "u",
        "Ä": "A", "Ö": "O", "Ü": "U"   // probably more to come
    }
    return ( s.replace(makeSortString.translate_re, function(match) {
        return translate[match];
    }))
}

export const findByDisplaynames = (haystack, arr) => {
    let ggg = arr.map((item, index) => {
        return crapRemoval(item).toLowerCase();
    })
    let catStrs = []
    let hashCats = []
    let catObjs = []
    haystack.forEach(function(el) {
            if(ggg.indexOf(crapRemoval(el.displayname)) > -1) {
                catStrs.push(crapRemoval(el.displayname).toLowerCase())
                hashCats.push(el.id)
                catObjs.push(el)
            }
    })
    return [hashCats, catStrs, catObjs]
}

export const findByIDs = (haystack, arr) => {
    const ggg = []
    arr.forEach((item) => {
            if(haystack[parseInt(item)]) ggg.push(parseInt(item))
    })
    let catStrs = []
    let hashCats = []
    let catObjs = []
    haystack.forEach(function(el) {
        if(ggg.indexOf(el.id) > -1) {
            catStrs.push(crapRemoval(el.displayname).toLowerCase())
            hashCats.push(el.id)
            catObjs.push(el)
        }
    })
    return [hashCats, catStrs, catObjs]
}

export const openLink = (url) => {
    window.location.href = url
}

export const arrayShuffle = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1
        // And swap it with the current element.
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
    }
    return array
}

export const getStartOfDay =()=> {
    const ms = new Date().getTime()
    const resetHours = new Date(ms).setHours(2,5,0,0)//add 2 hours (+5 min mumbo jumbo) for server time
    return new Date(resetHours).getTime()
}

export const getEndOfDay =()=>{
    const ms = getStartOfDay()
    return ms + ((24 * 60 * 60 * 1000)  - 1000)
}